<template>
  <nav>
    <navigation-item
      :to="{ name: 'dashboard' }"
      :text="$t('dailySchedule')"
      :icon-size="31"
      item-icon="plan"
      class="nav-icon"
    />
    <navigation-item
      :to="{ name: 'report' }"
      :text="$t('report')"
      :icon-size="32"
      item-icon="graph"
      class="nav-icon nav-icon--report"
    />
    <navigation-item
      :text="$t('add')"
      :icon-size="32"
      item-icon="add-filled"
      class="nav-icon"
      @click.native="showAddFood = true"
    />
    <navigation-item
      v-if="showCalculator"
      :to="{ name: 'calculator' }"
      :text="$t('calculator.title')"
      :icon-size="24"
      item-icon="calculator"
      class="nav-icon nav-icon--calculator"
    />
    <navigation-item
      :to="{ name: 'profile' }"
      :text="$t('profile.title')"
      :icon-size="30"
      item-icon="account"
      class="nav-icon"
    />
    <van-overlay
      :show="showAddFood"
      @click="showAddFood = false"
    >
      <div class="overlay-content">
        <van-button
          type="plain"
          block
          :to="{ name: 'FoodList', params: { mealType: 'breakfast' } }"
          :icon="$icon('breakfast')"
          :text="$t('food.mealTypes.breakfast')"
        />
        <van-button
          type="plain"
          block
          :to="{ name: 'FoodList', params: { mealType: 'lunch' } }"
          :icon="$icon('lunch')"
          :text="$t('food.mealTypes.lunch')"
        />
        <van-button
          type="plain"
          block
          :to="{ name: 'FoodList', params: { mealType: 'dinner' } }"
          :icon="$icon('dinner')"
          :text="$t('food.mealTypes.dinner')"
        />
        <van-button
          type="plain"
          block
          :to="{ name: 'FoodList', params: { mealType: 'snack' } }"
          :icon="$icon('snack')"
          :text="$t('food.mealTypes.snack')"
        />
        <van-button
          type="plain"
          block
          :to="{ name: 'FoodList', params: { mealType: 'template' } }"
          :icon="$icon('dayplan')"
          :text="$t('dailySchedule')"
        />
        <van-button
          type="plain"
          block
          :to="{
            name: 'calculator',
            params: {
              title: $t('food.create'),
            },
          }"
          :icon="$icon('food')"
          :text="$t('food.title')"
        />
        <van-button
          type="plain"
          round
          icon="cross"
          @click="showAddFood = false"
        />
      </div>
    </van-overlay>
  </nav>
</template>

<script>
import { diagnosesWithoutCalculator } from '@/models/diagnosis';
import NavigationItem from '../NavigationItem';

export default {
  components: {
    NavigationItem,
  },
  data() {
    return {
      diagnosis: null,
      showAddFood: false,
    };
  },
  computed: {
    showCalculator() {
      return !diagnosesWithoutCalculator.includes(this.$store.getters['user/diagnosisName']);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;

nav {
  display: flex;
  background-color: config.$brand-primary-dark;
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1);
}

.van-overlay {
  display: flex;
  align-items: flex-end;
  background-color: rgba(36, 37, 38, 0.11);
  backdrop-filter: blur(10px);
}

.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.van-button {
  $navItems: 6;

  border: none;
  margin-bottom: 1.375rem;

  @for $i from 1 through $navItems {
    &:nth-child(#{$i}) {
      transition-delay: #{(($navItems + 1) - $i) * 50}ms;
    }
  }

  // stylelint-disable no-descending-specificity
  .van-fade-leave-active &,
  .van-fade-enter-active & {
    transition-duration: 100ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5); // bounce
  }

  // Visible state.
  .van-fade-leave-active &,
  .van-fade-enter-active.van-fade-enter-to & {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  // Hidden state.
  .van-fade-enter-active &,
  .van-fade-leave-active.van-fade-leave-to & {
    opacity: 0;
    transform: translateY(50px) scale(0);
  }
  // stylelint-enable no-descending-specificity

  &--block {
    max-width: 200px;
    border-radius: 40px;

    .van-button__content {
      display: flex;
    }

    .van-button__icon {
      flex: 1 1 30%;
      margin-right: 0.54em;
      font-size: 1.5rem;
      text-align: right;
    }

    .van-button__text {
      flex: 1 1 70%;
      text-align: left;
    }
  }

  &--round {
    width: 40px;
    height: 40px;
    margin-bottom: 60px;
    font-size: 20px;
  }
}
</style>
