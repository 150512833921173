<template>
  <div id="app">
    <template v-if="!isLoading">
      <main
        class="page-content"
        role="content"
      >
        <router-view />
      </main>
      <footer
        v-if="showNavigation"
        class="page-footer"
      >
        <navigation />
      </footer>
    </template>
    <LoadingScreen
      v-else
      :enter-application="enterApplication"
      :is-loading="isLoading"
    />
    <van-notify
      v-model="updateExists"
      type="warning"
      @click="refreshApp"
    >
      <van-icon
        name="replay"
        style="margin-right: 4px;"
      />
      <span>{{ $t('app.updateAvailable') }}</span>
    </van-notify>
  </div>
</template>

<script>
import LoadingScreen from '@/views/Onboarding/LoadingScreen';
import UpdatePWA from './mixins/update';

export default {
  components: {
    LoadingScreen,
  },
  mixins: [UpdatePWA],
  data() {
    return { isLoading: true };
  },
  computed: {
    showNavigation() {
      return this.$route.matched.some(
        (item) => item.meta.showNavigation === true,
      );
    },
  },
  mounted() {
    /**
     * Session storage dayplay selected date
     * We use the session storage to save the date selected by the user during the session, in this
     * way, we can check that the user's session is active.
     * If it isn't active, we set the session to today's date and modify the variable saved in the
     * store to initialize the dashboard view with today's date.
     */
    if (!this.$session.exists('dayplan/selectedDate')) {
      const date = new Date();
      this.$store.commit('dayplan/selectDate', date);
      this.$session.set('dayplan/selectedDate', date);
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },
  methods: {
    enterApplication() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.page-content {
  display: flex;
  flex-direction: column;

  .page--welcome & {
    display: block;
  }
}

.page-footer {
  position: sticky;
  z-index: 1000;
  bottom: 0;

  @media print {
    display: none;
  }
}

.update-available {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 2rem;
  background-color: #fff;
  box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.1);
}
</style>
