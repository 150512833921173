<template>
  <div class="branding">
    <span>Ein Service von</span>
    <img
      src="../../assets/icons/branding.svg"
      class="branding__image"
    >
  </div>
</template>

<script>
export default {
  name: 'Branding',
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.branding {
  width: 100%;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: config.$color-darker;
    font-size: config.$font-size-sm;
    margin-right: 14px;
    margin-top: 8px;
  }
  &__image {
    @media (max-width: 640px) {
      width: 158px;
      height: 40px;
    }
  }
}
</style>
