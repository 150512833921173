<template>
  <component
    :is="tag"
    :to="to || undefined"
  >
    <van-icon
      v-if="itemIcon"
      :name="$icon(itemIcon)"
      :size="iconSize"
    />
    <span class="navigation-item__text">{{ text }}</span>
  </component>
</template>

<script>
export default {
  props: {
    to: { required: false, type: [String, Object], default: null },
    itemIcon: { type: String, default: null },
    iconSize: { type: Number, default: 16 },
    text: { type: String, required: true },
  },
  computed: {
    tag() {
      return this.to ? 'router-link' : 'a';
    },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;

a {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8em;
  cursor: pointer;
  color: #fff;
  font-size: 0.625rem;
  text-align: center;
  background-color: config.$brand-primary-dark;

  &.router-link-active {
    background-color: config.$brand-primary;
  }
}

i {
  margin-bottom: 0.3125rem;

  .nav-icon--report & {
    margin-bottom: 0.1875rem;
  }

  .nav-icon--calculator & {
    margin-bottom: 0.5rem;
  }
}
</style>
